import { useLazyQuery, useQuery } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import { useEffect, useRef, useState } from 'react';
import { SubscriptionProps } from '../interfaces/Components';
import { SubscriptionType } from '../interfaces/Subscription';
import styles from '../sass/components/Subscription.module.scss';
import {
  GET_SUBSCRIPTION_TYPES,
  RECALCULATE_INSPECTION_PRICES,
} from '../util/gql';
import Loader from './Loader';
import ModalButtonsContainer from './ModalButtonsContainer';
import CustomRadio from './Radio';

export default function Subscription(
  {
    onClose,
    onClick,
    setSelectedSubscription,
    selectedSubscription,
    vehicleSubscription,
    userVehicleId,
    lineItems,
    inspectionMileage,
    setDiscountedLineItems,
  }: SubscriptionProps,
) {
  const [
    activeSubscription,
    setActiveSubscription,
  ] = useState<SubscriptionType | null>(null);

  const {
    data: subscriptionTypes,
    loading,
  } = useQuery(GET_SUBSCRIPTION_TYPES, {
    onCompleted: (data) => {
      if (vehicleSubscription) {
        const selectedSubscriptionType: SubscriptionType = data
          ?.getAllSubscriptionTypes
          ?.find((
            subsType: SubscriptionType,
          ) => subsType.id === vehicleSubscription.subscriptionTypeId);
        setActiveSubscription(selectedSubscriptionType);
      }
    },

  });

  const {
    loading: pricesLoading,
  } = useQuery(RECALCULATE_INSPECTION_PRICES, {
    variables: {
      mileage: inspectionMileage,
      userVehicleId,
      lineItems: lineItems?.map((item) => ({
        id: item.id,
        serviceId: item.service.id,
        price: item.price,
      })),
    },
    fetchPolicy: 'network-only',
    onCompleted: (resultData) => {
      if (resultData?.recalculateInspectionPrices && setDiscountedLineItems) {
        setDiscountedLineItems(resultData.recalculateInspectionPrices);
      }
    },
  });

  const handleOnClose = () => {
    setSelectedSubscription();
    onClose();
  };

  if (loading || pricesLoading) {
    return (
      <Loader />
    );
  }

  const priceLine = (price: number, frequency: string) => {
    const formattedFrequency = frequency === 'ANNUALLY' ? 'Year' : 'Month';
    const formattedPrice = `$${(price / 100)}`;
    return `${formattedPrice} / ${formattedFrequency}`;
  };

  const ActiveSubscriptionContainer = () => (
    <div>
      <p className={styles.activeSubscriptionSubTitle}>
        Your active subscriptions:
      </p>
      <ul className={styles.activeSubscriptionList}>
        <div className={styles.activeSubscriptionNameContainer}>
          <CheckIcon />
          <li className={styles.activeSubscriptionName}>
            {activeSubscription?.aliasName}
          </li>
        </div>
        <li className={styles.activeSubscriptionDescription}>
          {activeSubscription?.description}
        </li>
      </ul>
    </div>
  );

  const SelectSubscriptionContainer = () => (
    <div className={styles.selectSubscriptionContainer}>
      {subscriptionTypes
        .getAllSubscriptionTypes
        .map((subscriptionType: SubscriptionType) => (
          <div
            key={subscriptionType.id}
            className={styles.subscriptionItemContainer}
          >
            <CustomRadio
              name={subscriptionType.name}
              value={subscriptionType.id.toString()}
              label={subscriptionType.aliasName}
              id={subscriptionType.id.toString()}
              onChange={() => setSelectedSubscription(subscriptionType)}
              checked={selectedSubscription?.id === subscriptionType.id}
              className={styles.radioLabel}
            />
            <p className={styles.textLine}>
              {priceLine(subscriptionType.price, subscriptionType.frequency)}
            </p>
            <p className={styles.textLine}>
              {subscriptionType.description}
            </p>
          </div>
        ))}
    </div>
  );

  return (
    <div className={styles.body}>
      <h4>Step: 5/6: Subscription (optional)</h4>
      <p className={styles.headerTitle}>
        Before completing this order,
        consider subscribing this vehicle to one of our
        available car maintenance plans:
      </p>
      {activeSubscription
        ? <ActiveSubscriptionContainer />
        : <SelectSubscriptionContainer />}
      <ModalButtonsContainer
        onClose={handleOnClose}
        onClick={onClick}
        label="Continue"
      />
    </div>
  );
}
