/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { EditLineItemProps } from '../interfaces/Components';
import styles from '../sass/components/LineItemEditor.module.scss';
import ModalButtonsContainer from './ModalButtonsContainer';
import TextInput from './TextInput';
import Select from './EditorSelect';
import Button from './Button';
import { ServiceItem } from '../interfaces/WorkOrder';

export default function LineItemsEditor({
  onClose,
  onSubmit,
  workOrderDetails,
  setWorkOrderDetails,
  serviceItem,
  setServiceItem,
  services,
  poNumber,
  setPoNumber,
}: EditLineItemProps) {
  const findServiceIdByName = (serviceName: string) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const service = services.find((service) => service.name === serviceName);
    return service ? service.id.toString() : '1';
  };
  const findServiceById = (id: number) => services.find((service) => service.id === id);
  const getValidPrice = (item: ServiceItem) => {
    if (
      item.discountedPrice !== null
      && item.discountedPrice !== undefined
      && Number.isFinite(item.discountedPrice)
    ) {
      return item.discountedPrice;
    }
    return item.price !== null && item.price !== undefined ? item.price.toString() : '';
  };
  return (
    <div className={styles.body}>
      <h4>Edit Line Items</h4>
      <p>
        Services:
      </p>
      <ul>
        { serviceItem && serviceItem?.map((item, index) => (
          <>
            <li key={`service-input-${index}`}>
              <div className={styles.container}>
                <Select
                  label=""
                  value={item.name ? findServiceIdByName(item.name)
                    : ''}
                  loading={false}
                  id={`service-input-${index}`}
                  placeholder="Select service."
                  onChange={(e) => {
                    const id = Number(e.currentTarget.value);
                    const selectedService = findServiceById(id);
                    if (selectedService) {
                      setServiceItem(serviceItem.map((it, idx) => (idx === index ? {
                        ...it,
                        name: selectedService.name,
                        description: selectedService.description,
                        serviceId: selectedService.id,
                        price: (selectedService.value / 100),
                      } : it)));
                    }
                  }}
                >
                  {services.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.name}
                    </option>
                  ))}
                </Select>

                <TextInput
                  className={styles.individualItem}
                  title="Price $"
                  placeholder="Price"
                  type="number"
                  onChange={(e) => {
                    setServiceItem(serviceItem.map((it, idx) => (idx === index ? {
                      ...it,
                      price: Number(e.currentTarget.value),
                      quantity: 1,
                    } : it)));
                  }}
                  value={getValidPrice(item).toString()}
                  min={0}
                />
                <Button
                  onClick={() => {
                    setServiceItem(serviceItem.filter((_, idx) => idx !== index));
                  }}
                  variant="primary"
                  size="small"
                  className={styles.icon}
                >
                  -
                </Button>
              </div>
            </li>
          </>
        ))}
      </ul>
      <br />
      <Button
        onClick={() => {
          setServiceItem(serviceItem.concat([{
            id: 0,
            price: 0,
            tax: 0,
            quantity: 1,
            name: '',
            description: '',
          }]));
        }}
        variant="primary"
        size="small"
        className={styles.addButton}
      >
        +
      </Button>
      <br />
      <p>
        Po Number:
      </p>
      <br />
      <TextInput
        className={styles.poNumber}
        title=""
        placeholder="PO Number"
        type="text" // Use 'text' for input type instead of 'string'
        onChange={(e) => setPoNumber(e.currentTarget.value)}
        value={poNumber}
      />

      <br />
      <ModalButtonsContainer
        onClose={onClose}
        onClick={onSubmit}
        label="Submit"
      />
    </div>
  );
}
