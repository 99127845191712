import cx from 'classnames';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import styles from '../sass/components/SubscriptionModal.module.scss';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';
import { Subscription } from '../interfaces/Subscription';
import Badge from './Badge';
import { CANCEL_SUBSCRIPTION, PAY_LATER_SUBSCRIPTION } from '../util/gql';
import ConfirmationModal from './ConfirmationModal';
import { formatDate } from '../util/formatDate';
import { calculateRenewingDate } from '../util/subcriptionHelper';
import { api } from '../util/api';

interface ErrorModalProps {
  message: string,
  title: string
}
interface SubscriptionModalProps {
  onClose: () => void;
  subscriptions: Subscription[];
  refetchSubscriptions: () => void;
}

export default function SubscriptionModal({
  onClose,
  subscriptions = [],
  refetchSubscriptions,
}: SubscriptionModalProps) {
  const [
    selectedSubscriptionId,
    setSelectedSubscriptionId,
  ] = useState<number | null>(null);
  const [
    cancelSubscriptionModal,
    setCancelSubscriptionModal,
  ] = useState<boolean>(false);
  const [cancelErrorModal, setCancelErrorModal] = useState<boolean>(false);

  const [
    downloadInvoiceErrorModal,
    setDownloadInvoiceErrorModal,
  ] = useState<boolean>(false);

  const handleCloseErrorModal = () => {
    setDownloadInvoiceErrorModal(false);
    setSelectedSubscriptionId(null);
    refetchSubscriptions();
  };

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION, {
    onCompleted: () => {
      setCancelSubscriptionModal(false);
      setSelectedSubscriptionId(null);
      refetchSubscriptions();
    },
    onError: () => setCancelErrorModal(true),
    fetchPolicy: 'network-only',
  });

  const handleCancelSubscription = (subscriptionId: number) => {
    setSelectedSubscriptionId(subscriptionId);
    setCancelSubscriptionModal(true);
  };

  const confirmCancelSubscription = async () => {
    if (selectedSubscriptionId !== null) {
      await cancelSubscription({
        variables: { id: selectedSubscriptionId },
      });
    }
  };

  const handleOnCloseErrorModal = (): void => {
    setCancelErrorModal(false);
    setDownloadInvoiceErrorModal(false);
  };

  const [payLaterSubscription] = useMutation(PAY_LATER_SUBSCRIPTION);

  const handlePayLaterSubscription = async (subscriptionId: number) => {
    try {
      const { data } = await payLaterSubscription({
        variables: { subscriptionId },
      });

      if (data?.payLaterSubscription) {
        window.open(data.payLaterSubscription, '_blank');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };

  const downloadInvoice = async (subscriptionId: number) => {
    try {
      const response = await api.get(
        `account/franchisee/invoices/subscription/${subscriptionId}`, {
          responseType: 'arraybuffer',
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = `invoice_subscription_${subscriptionId}.pdf`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch {
      setDownloadInvoiceErrorModal(true);
    }
  };

  const DownloadInvoiceErrorModal = ({ message, title }: ErrorModalProps) => (
    <ConfirmationModal
      onClose={handleCloseErrorModal}
      message={message}
      title={title}
      open={downloadInvoiceErrorModal}
    />
  );

  const getStatusTitle = (status: string) => {
    switch (status) {
      case 'ACTIVE':
        return 'Active';
      case 'PENDING':
        return 'Pending Payment';
      case 'CANCELLED':
        return 'Cancelled';
      case 'RENEWAL_ERROR':
        return 'Renewal Error';
      default:
        return 'Unknown';
    }
  };

  const [expandedSubscription,
    setExpandedSubscription] = useState<number | null>(null);

  const toggleExpand = (subscriptionId: number) => {
    setExpandedSubscription(expandedSubscription === subscriptionId
      ? null : subscriptionId);
  };

  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Subscriptions</h4>
        <p>Here you can manage the subscriptions.</p>

        <div className={styles.subscriptionList}>
          {subscriptions.map((subscription) => {
            const nextRenewalDate = calculateRenewingDate(subscription);
            return (
              <div key={subscription.id} className={styles.subscriptionItem}>
                <div
                  className={cx(styles.vanCard, {
                    [styles.selectedRow]:
                    expandedSubscription === subscription.id,
                  })}
                >

                  <div className={styles.subscriptionRow}>
                    <div className={styles.subscriptionHeader}>
                      <Button
                        className={styles.expandButton}
                        variant="icon"
                        onClick={() => toggleExpand(subscription.id)}
                      >
                        <Arrow
                          className={cx(styles.arrowIcon, {
                            [styles.expandedArrow]:
                            expandedSubscription === subscription.id,
                          })}
                        />
                      </Button>
                      {subscription.subscriptionType.aliasName && (
                      <div className={styles.detailRow}>
                        {subscription.subscriptionType.aliasName}
                      </div>
                      )}
                    </div>

                    <Badge
                      variant="primary"
                      className={styles.unavailabilityReasonBadge}
                    >
                      {getStatusTitle(subscription.status)}
                    </Badge>
                    {subscription.subscriptionType.price && (
                    <div className={styles.detailRow}>
                      $
                      {(subscription.subscriptionType.price / 100).toFixed(2)}
                    </div>
                    )}
                  </div>
                </div>
                {expandedSubscription === subscription.id && (
                <div className={styles.serviceInfo}>
                  <ul>
                    <li>
                      <strong>Mobile Oil Changes &amp; Tire Rotations:</strong>
                      {' '}
                      Every 8,000 km or 5,000 miles with full synthetic oil
                      and tire rotations.
                    </li>
                    <li>
                      <strong>Battery Replacement:</strong>
                      {' '}
                      Every 48 months for reliable starts.
                    </li>
                    <li>
                      <strong>Windshield Wiper Replacements:</strong>
                      {' '}
                      Up to 2 sets per year.
                    </li>
                    <li>
                      <strong>Cabin and Engine Air Filter Changes:</strong>
                      {' '}
                      Cabin: every 24,000 km; Engine: every 42,000 km.
                    </li>
                    <li>
                      <strong>Seasonal Tire Changeover:</strong>
                      {' '}
                      On-rim seasonal adjustments.
                    </li>
                    <li>
                      <strong>Roadside Assistance Credit:</strong>
                      {' '}
                      Up to $70/year.
                    </li>
                    <li>
                      <strong>Road Trip Inspections:</strong>
                      {' '}
                      Up to 3 times/year.
                    </li>
                    <li>
                      <em>
                        Flexibility for early services with adjusted
                        pricing.
                      </em>
                    </li>
                    <li>
                      <strong>Note:</strong>
                      {' '}
                      Not for commercial vehicles.
                    </li>
                  </ul>
                </div>
                )}
                <div className={styles.subscriptionDetails}>
                  {subscription.status === 'ACTIVE'
                  && subscription.renewedAt && (
                  <>
                    <div className={styles.detailRow}>
                      <strong>Last Renewed On:</strong>
                      {' '}
                      {formatDate(subscription.renewedAt)}
                    </div>
                    <div className={styles.detailRow}>
                      <strong>Next Payment Day:</strong>
                      {' '}
                      {formatDate(nextRenewalDate.toString())}
                    </div>
                  </>
                  )}
                  {subscription.status === 'CANCELLED' && (
                  <div className={styles.subscriptionDetail}>
                    <strong>Cancellation Date:</strong>
                    {' '}
                    {formatDate(subscription.updatedAt)}
                  </div>
                  )}
                </div>
                <div className={styles.subscriptionActions}>
                  {subscription.status !== 'CANCELLED' && (
                  <>
                    <Button
                      onClick={
                          () => handleCancelSubscription(subscription.id)
                        }
                      variant="secondary"
                    >
                      Cancel
                    </Button>
                    <ConfirmationModal
                      message="Are you sure you want to cancel
                        you subscription?"
                      onClose={() => setCancelSubscriptionModal(false)}
                      onSubmit={confirmCancelSubscription}
                      title="Cancel Subscription"
                      open={cancelSubscriptionModal}
                    />
                    <ConfirmationModal
                      message="Error: Could not cancel your
                        subscription, try again later."
                      onClose={handleOnCloseErrorModal}
                      title="Cancel Subscription"
                      open={cancelErrorModal}
                    />
                  </>
                  )}
                  {(subscription.status === 'PENDING'
                || subscription.status === 'ACTIVE')
                && subscription.invoices.length > 0
                && subscription.updatedAt !== null
                && (
                <Button
                  onClick={() => downloadInvoice(subscription.id)}
                  variant="primary"
                >
                  Download Invoice
                </Button>
                )}
                  {(subscription.status === 'PENDING'
                || subscription.status === 'RENEWAL_ERROR')
                && subscription.updatedAt !== null
                && (
                <Button
                  onClick={() => handlePayLaterSubscription(subscription.id)}
                  variant="primary"
                >
                  Pay
                </Button>
                )}
                </div>
              </div>
            );
          })}
        </div>

        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} variant="tertiary">
            Close
          </Button>
        </div>
      </div>
      {downloadInvoiceErrorModal && (
        <DownloadInvoiceErrorModal
          message="Error downloading the invoice, please try again."
          title="Invoice Download"
        />
      )}
    </div>
  );
}
