/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Delete from '@mui/icons-material/Delete';
import cx from 'classnames';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setPostalCode } from '../features/postalCode/postalCodeSlice';
import { Appointment } from '../features/serviceLocation/serviceLocationSlice';
import {
  setWorkOrderUnavailabilityReason,
} from '../features/workOrder/workOrderSlice';
import ErrorModalContainer from './CompleteModal';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import {
  MileageEventType,
  WorkOrder,
  WorkOrderProps,
} from '../interfaces/WorkOrder';
import { invoiceStatus } from '../lang/en/payment.json';
import styles from '../sass/components/CustumerWorkOrderDetails.module.scss';
import {
  ROUTE_ACCOUNT_APPOINTMENTS,
} from '../util/constants';
import { convertToCurrency } from '../util/currencyFormat';
import { dayTimeString } from '../util/formatDate';
import {
  CANCEL_WORK_ORDER,
  GET_POSTAL,
  GET_WORK_ORDER,
  UPDATE_SCHEDULED_TIME,
} from '../util/gql';
import { getActiveVehicleSubscription, getCurrentVehicleSubscription } from '../util/subcriptionHelper';
import AppointmentDatePicker from './AppointmentDatePicker';
import Button from './Button';
import DetailsRow from './DetailsRow';
import ListRow from './ListRow';
import ConfirmationModal from './ConfirmationModal';
import { calculateOilByCountry } from '../util/oilHelper';

export default function CustomerWorkOrderDetails() {
  const [workOrderDetails, setWorkOrderDetails] = useState<WorkOrderProps>();

  const [
    deleteWorkOrderModal,
    setDeleteWorkOrderModal,
  ] = useState<boolean>(false);

  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [deleteErrorModal, setDeleteErrorModal] = useState<boolean>(false);
  const [editScheduledTime, setEditScheduledTime] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState<Appointment>();

  const [canReschedule, setCanReschedule] = useState<boolean>(false);

  const [
    isValidAddress,
    setIsValidAddress,
  ] = useState<undefined | boolean>(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const workOrderId = useAppSelector((state) => state.workOrder.id)
    || Number(id);

  const unavailabilityReason = useAppSelector(
    (state) => state.workOrder.unavailabilityReason,
  );

  const vehicleName = (
    { vehicle }: WorkOrder,
  ) => `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.engine}`;

  const serviceName = (
    { vehicle, lineItems }: WorkOrder, code: string, countryCode: string,
  ) => lineItems
    .map((lineItem) => {
      if (lineItem.service?.serviceType.name === 'OIL_CHANGE') {
        const adjustedOilVolume = calculateOilByCountry(String(countryCode), vehicle.oilVolume);
        return `${lineItem.name} - ${adjustedOilVolume}${code} of oil`;
      }
      return lineItem.name;
    });

  const backToWorkOrderList = (): void => navigate(
    ROUTE_ACCOUNT_APPOINTMENTS,
  );

  const [updatePostalCode] = useLazyQuery(GET_POSTAL, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { isInService, code } = data.getPostal;
      if (isInService) {
        dispatch(setPostalCode(code));
      }
      setIsValidAddress(isInService);
    },
  });

  const getWorkOrderLineItems = (workOrder: WorkOrder) => workOrder
    .lineItems
    .filter((lineItem) => lineItem.service);

  const [getWorkOrder, { loading }] = useLazyQuery(GET_WORK_ORDER, {
    onCompleted: (data) => {
      const workOrder: WorkOrder = data?.getWorkOrderById;
      const scheduled = new Date(workOrder.scheduledAt);
      const timeToReschedule = scheduled.getTime() - 3 * 60 * 60 * 1000;
      const current = new Date();
      setCanReschedule(new Date(timeToReschedule) > current);

      setDateTime({
        date: workOrder.scheduledAt.toString(),
        routeId: workOrder.address.postalCode,
      });
      updatePostalCode({ variables: { code: workOrder.address.postalCode } });
      setWorkOrderDetails({
        id: workOrder.id,
        user: workOrder.contact?.email || 'Contact was deleted',
        userId: workOrder.user.id,
        userDiscountAmount: workOrder.user.discountAmount,
        userDiscountType: workOrder.user.discountType,
        address: `${workOrder.address.street}, ${workOrder.address.postalCode} ${workOrder.address.city}, ${workOrder.address.province}`,
        addressLat: workOrder.address.lat,
        addressLng: workOrder.address.lng,
        contactName: workOrder.franchise.name,
        contactEmail: workOrder.franchise.managerEmail,
        contactPhone: workOrder.franchise.phoneNumber,
        vehicle: vehicleName(workOrder),
        oilWeight: workOrder.vehicle.recommendedOil.weight,
        vehicleId: workOrder.vehicle.id,
        userVehicleId: workOrder.userVehicle.id,
        vin: workOrder.userVehicle.vin,
        name: workOrder.userVehicle.name,
        franchise: workOrder.franchise.name,
        franchiseId: workOrder.franchise.id,
        scheduledAt: dayTimeString(workOrder.scheduledAt),
        notes: workOrder.notes,
        cancelReason: workOrder.cancelReason,
        proofOfCancelLocation: workOrder.proofOfCancelLocation,
        status: workOrder.status,
        poNumber: workOrder.poNumber,
        invoiceStatus: invoiceStatus[
          workOrder.invoice?.status as keyof typeof invoiceStatus
        ],
        billedAmount: convertToCurrency(workOrder.invoice?.billedAmount),
        invoiceId: workOrder.invoice?.id,
        lineitems: getWorkOrderLineItems(workOrder),
        services: serviceName(workOrder,
          (workOrder.address.countryCode === 'CA' ? 'L' : 'QT'), workOrder.address.countryCode),
        unavailabilityReason,
        vehicleSubscription: getCurrentVehicleSubscription(
          workOrder.userVehicle.subscriptions,
        ),
        activeSubscription: getActiveVehicleSubscription(
          workOrder.userVehicle.subscriptions,
        ),
        engine: workOrder.vehicle.engine,
        year: workOrder.vehicle.year,
        model: workOrder.vehicle.model,
        make: workOrder.vehicle.make,
      });
    },
    onError: backToWorkOrderList,
    fetchPolicy: 'network-only',
  });

  const handleOnCloseErrorModal = (): void => {
    setErrorModal(false);
    backToWorkOrderList();
  };

  const UpdateScheduledTimeErrorModal = () => (
    <ErrorModalContainer
      onClose={handleOnCloseErrorModal}
      invoiceId={null}
      currentReader={null}
      open={errorModal}
      title="Update Work Order"
      message="Error: Could not change work order scheduled date, try again later."
    />
  );

  const [deleteWorkOrder] = useMutation(CANCEL_WORK_ORDER, {
    variables: {
      workOrderId: workOrderDetails?.id,
      cancelReason: 'customer_cancelled',
    },
    onCompleted: () => backToWorkOrderList(),
    onError: () => setDeleteErrorModal(true),
    fetchPolicy: 'network-only',
  });

  const [updateScheduledTime] = useMutation(UPDATE_SCHEDULED_TIME, {
    variables: {
      id: workOrderDetails?.id,
      scheduledAt: parseISO(dateTime?.date as string),
    },
    onCompleted: (data) => {
      dispatch(setWorkOrderUnavailabilityReason(
        data?.updateScheduledTime.reason,
      ));
      getWorkOrder({ variables: { id: workOrderId } });
    },
    onError: () => {
      setErrorModal(true);
    },
  });

  const saveScheduledTime = () => {
    setEditScheduledTime(false);
    updateScheduledTime();
  };

  useEffect(() => {
    if (!workOrderId) backToWorkOrderList();
    getWorkOrder({ variables: { id: workOrderId } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWorkOrder, workOrderId]);

  const handleOnCloseDeleteErrorModal = (): void => {
    setDeleteErrorModal(false);
    backToWorkOrderList();
  };

  const Loading = () => (
    <p className={styles.loading}>Loading...</p>
  );

  const contactList = [
    workOrderDetails?.contactName ?? '',
    workOrderDetails?.contactEmail ?? '',
    workOrderDetails?.contactPhone ?? '',
  ];
  const scheduledList = [
    `Please note that you may only reschedule your appointment up to 3 hours before the scheduled time.
        `,
    workOrderDetails?.scheduledAt ?? '',
  ];

  const displayName = workOrderDetails?.name ? `Personal Identifier: ${workOrderDetails.name}` : '';
  const vehicleList = [
    workOrderDetails?.vehicle ?? '',
    `Oil weight: ${workOrderDetails?.oilWeight}`,
    displayName,
  ];
  const WorkOrderDetailsRow = () => (
    <div className={styles.workOrderDetailsContainer}>
      <div className={styles.backButtonContainer}>
        <div className={styles.backButtonContent}>
          <Button
            className={cx(styles.caret)}
            type="button"
            onClick={backToWorkOrderList}
            variant="icon"
          >
            <Arrow />
          </Button>
          <h5>
            Your Appointment ID:
            {' '}
            {workOrderDetails?.id}
          </h5>
        </div>
      </div>
      <DetailsRow
        title="Your Email"
        text={workOrderDetails?.user}
      />
      <DetailsRow
        title="Service Address"
        text={workOrderDetails?.address}
      />
      <ListRow
        title="Contact"
        list={contactList}
      />
      <ListRow
        title="Vehicle"
        list={vehicleList}
      />
      <ListRow
        title="Services"
        list={workOrderDetails?.services}
      />
      {editScheduledTime && (
      <h4 className={styles.editScheduledAtTitle}>
        Select a new date:
      </h4>
      )}
      <ListRow
        title={editScheduledTime ? '' : 'Scheduled At'}
        list={scheduledList}
      >
        {!editScheduledTime && canReschedule
        && isValidAddress && (
        <Button
          onClick={() => setEditScheduledTime(true)}
          className={styles.editButton}
        >
          Edit
        </Button>
        )}
        {editScheduledTime && (
        <div className={styles.editScheduledAt}>
          <AppointmentDatePicker
            onSelect={(e) => {
              setDateTime({ date: e.date, routeId: e.routeId, isBooked: e.isBooked });
            }}
            value={dateTime}
            timeNoLongerAvailable={false}
            franchiseId={workOrderDetails?.franchiseId}
          />
          <Button
            onClick={saveScheduledTime}
            className={styles.saveCancelButton}
          >
            Save
          </Button>
          <Button
            onClick={() => setEditScheduledTime(false)}
            className={styles.saveCancelButton}
          >
            Cancel
          </Button>

        </div>
        )}
      </ListRow>
      <DetailsRow
        title="Cancel Appointment"
        text="Please note that you may only cancel your appointment up to 3 hours before the scheduled time."
      >
        { canReschedule && (
        <>
          <Button
            className={styles.deleteButton}
            variant="secondary"
            onClick={() => setDeleteWorkOrderModal(true)}
          >
            Cancel
          </Button>
          <ConfirmationModal
            message="Are you sure you want to cancel you appointment with Go Oil?"
            onClose={() => setDeleteWorkOrderModal(false)}
            onSubmit={deleteWorkOrder}
            title="Cancel Appointment"
            open={deleteWorkOrderModal}
          />
          <ConfirmationModal
            message="Error: Could not cancel your appointment, try again later."
            onClose={handleOnCloseDeleteErrorModal}
            title="Cancel Appointment"
            open={deleteErrorModal}
          />
        </>
        )}
      </DetailsRow>
      {errorModal && (
      <UpdateScheduledTimeErrorModal />
      )}
    </div>
  );

  return (
    <div>
      {loading ? <Loading /> : <WorkOrderDetailsRow />}
    </div>
  );
}
